<template>
  <ItemPrizesContainer :prize="prize">
    <v-col class="px-0" max-with="6rem">
      <v-btn
        block
        small
        color="primary"
        class="white--text"
        elevation="0"
        @click="redeemPrize"
      >
        {{ $t("redeem") }}
      </v-btn>
    </v-col>
  </ItemPrizesContainer>
</template>

<script>
export default {
  name: "ItemPrizesRedeemComponent",
  props: {
    prize: { type: Object, required: true },
  },
  components: {
    ItemPrizesContainer: () => import("../ItemPrizesContainer.vue"),
  },
  methods: {
    async redeemPrize() {
      this.$emit("showDialogRedeemPrize", this.prize);
    },
  },
};
</script>
